import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  Badge,
  Tooltip,
  Select,
} from "antd";
import _ from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CardService } from "services/CardService";
import { GiaiDauService } from "services/GiaiDauService";
const FormImport = (props, ref) => {
  const { onAfterSubmit } = props;
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idEntry, setIdEntry] = useState(0);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useImperativeHandle(ref, () => ({
    show: async (id) => {
      setOpen(true);
      setIdEntry(id);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setLoading(false);
  };

  const columns = [
    {
      title: "Tên giải",
      width: 150,
      render: (item) =>
        item?.error ? (
          <Tooltip title={item?.error}>
            <Badge count={1}>
              {/* <Avatar shape="square" size="large" /> */}
              <p>{item?.TenGiai}</p>
            </Badge>
          </Tooltip>
        ) : (
          <p>{item?.TenGiai}</p>
        ),
    },
    {
      title: "Số lượng",
      dataIndex: "SoLuongGiai",
      width: 100,
      // ellipsis: true,
    },
    {
      title: "Tỷ lệ giải",
      dataIndex: "TyLeGiai",
      width: 100,
    },
  ];
  // const [col, setCol] = useState(columns);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      TenGiai: "1ST",
      SoLuongGiai: 0,
      TyLeGiai: 0,
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "TyLeGiai" + fileExtension);
  };

  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        res.push({
          TenGiai: item[0],
          SoLuongGiai: Number(item[1]),
          TyLeGiai: Number(item[2]),
        });
      }
    });
    setData(res);
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _res = await GiaiDauService.TyLeGiaiThuong.import({
      idEntry: idEntry,
      List: data,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Import thành công");
      // let _data = _.cloneDeep(data);
      // _data = _data.map((item) => {
      //   if (_.map(_res.data, "maVach").includes(item.TenGiai)) return { ...item, error: _res.data?.find(v=>v.maVach ===item.MaVach)?.error };
      // });
      // setData(_data);
      onAfterSubmit();
      onClose();
    }
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    // setCol(columns);
  };
  return (
    <Drawer
      title="Import Excel"
      width="700px"
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button onClick={() => exportToCSV()} type="dashed">
            Tải file mẫu
          </Button>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}

          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                  loadDataImport(data);
                  // setCol(columns);
                } else {
                  setWorkbook(wb);
                  setSheetExcel(sheets);
                  setData();
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          // x: 2000,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormImport);
