import dayjs from "dayjs";

export const Format_Currency = (number) => {
  let num;
  const lengthArr = number?.toString().split(".").length;
  if (lengthArr > 1) {
    const parts = Number.parseFloat(number).toFixed(0).split(".");
    num =
      parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
      (parts[1] ? "," + parts[1] : "");
  } else {
    num = number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  return num;
};
export const Format_Datetime = (date) => {
  var m = new Date(date);
  var dateString =
    m.getUTCFullYear() +
    "/" +
    ("0" + (m.getUTCMonth() + 1)).slice(-2) +
    "/" +
    ("0" + m.getUTCDate()).slice(-2) +
    " | ";
  var time = m.getHours() + ":" + m.getMinutes();

  // ("0" + m.getUTCHours()).slice(-2) + ":" +
  // ("0" + m.getUTCMinutes()).slice(-2) + ":" +
  // ("0" + m.getUTCSeconds()).slice(-2);
  return dateString + time;
};
export const Format_Date = (date) => {
  var m = new Date(date);
  var dateString =
    ("0" + m.getUTCDate()).slice(-2) +
    "/" +
    ("0" + (m.getUTCMonth() + 1)).slice(-2) +
    "/" +
    m.getUTCFullYear();
  return dateString;
};

export function ConvertExcelDateToJSDate(excelDate) {
  const excelStartDate = new Date(1900, 0, 1); // 1/1/1900
  const jsDate = new Date(
    excelStartDate.getTime() + (excelDate - 1) * 24 * 60 * 60 * 1000
  );
  return dayjs(jsDate).format("DD-MM-YYYY");
}
