import { Box } from "@chakra-ui/react";
import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useRef,
} from "react";
import {
  Button,
  Input,
  Upload,
  Row,
  Col,
  Space,
  Drawer,
  notification,
  message,
  QRCode,
  Select,
  Checkbox,
  InputNumber,
} from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import { CongTyService } from "services/CongTyService";
import { FileUpload } from "primereact/fileupload";
import { HomeService } from "services/HomeService";
import { UploadService } from "services/UploadService";
import { CategoryService } from "services/CategoryService";
import { OtherService } from "services/OtherService";
const FormAdd = (props, ref) => {
  const { onAfterSubmit } = props;
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [logo, setLogo] = useState([]);
  const [mayIn, setMayIn] = useState([]);
  const [logoGD, setLogoGD] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const defaultErrors = {
    MaGD: null,
    MaKH: null,
    MaHinhThuc: null,
  };
  const [errors, setErrors] = useState(defaultErrors);
  const loadcategories = async () => {
    let _res = await CategoryService.Khac.getCatDatMayIn();
    setMayIn(_res.data ?? []);
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
      loadcategories();
    },
    update: async (data) => {
      setOpen(true);
      setData(data);
      loadcategories();
      setIsUpdate(true);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData({});
  };
  const applyChange = async (p, v) => {
    let _data = _.cloneDeep(data);
    _data[p] = v;
    setData(_data);
  };
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const validate = () => {
    let _errors = _.cloneDeep(errors);
    Object.keys(_errors)?.map((item) => {
      _errors[item] = data[item] ? null : "Không để trống";
    });
    setErrors(_errors);
    let valid = _.uniq(Object.values(_errors).filter((v) => v));
    return valid;
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _data = _.cloneDeep(data);
    // let valid = validate();
    // if (valid?.length > 0) {
    //   return;
    // }
    let _res = null;
    if (logo?.length > 0) {
      var formData = new FormData();
      formData.append("Image", logo[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.Logo = "https://upload.beesky.vn/" + _resupload.data;
        setData((pre) => {
          return { ...pre, Logo: _data.Logo };
        });
      }
    }
    if (logoGD?.length > 0) {
      var formData = new FormData();
      formData.append("Image", logoGD[0]);
      formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
      let _resupload = await UploadService.upload(formData);
      if (_resupload.status === 2000) {
        _data.LogoGiaiDau = "https://upload.beesky.vn/" + _resupload.data;
        setData((pre) => {
          return { ...pre, LogoGiaiDau: _data.LogoGiaiDau };
        });
      }
    }
    // if (bgdealer?.length > 0) {
    //   var formData = new FormData();
    //   formData.append("Image", bgdealer[0]);
    //   formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
    //   let _resupload = await UploadService.upload(formData);
    //   if (_resupload.status === 2000) {
    //     _data.BackgroundDealerUrl =
    //       "https://upload.beesky.vn/" + _resupload.data;
    //     setData((pre) => {
    //       return { ...pre, BackgroundDealerUrl: _data.BackgroundDealerUrl };
    //     });
    //   }
    // }
    // if (tableealer?.length > 0) {
    //   var formData = new FormData();
    //   formData.append("Image", tableealer[0]);
    //   formData.append("TenCTDK", localStorage.getItem("MaCTDK"));
    //   let _resupload = await UploadService.upload(formData);
    //   if (_resupload.status === 2000) {
    //     _data.TableDealderUrl = "https://upload.beesky.vn/" + _resupload.data;
    //     setData((pre) => {
    //       return { ...pre, TableDealderUrl: _data.TableDealderUrl };
    //     });
    //   }
    // }
    if (isUpdate) {
      _res = await CongTyService.updateCompany(_data);
    } else {
      _res = await CongTyService.addCompany(_data);
      setData((pre) => {
        return { ...pre, ID: _res.data };
      });
    }
    setLoading(false);
    if (_res.status === 2000) {
      openNotification(
        "success",
        "topRight",
        `${isUpdate ? "Sửa" : "Thêm"} công ty thành công!`
      );
      setIsUpdate(true);
      onAfterSubmit();
      if (close) onClose();
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };

  const onUpload = (e) => {
    console.log(e);
    // toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onBeforeUpload = (v) => {
    setLogo(v.files);
  };
  const onBeforeUploadGD = (v) => {
    setLogoGD(v.files);
  };
  const onBeforeUploadBGDealer = (v) => {
    // setBgdealer(v.files);
  };
  const onBeforeUploadTableDealer = (v) => {
    // setTableDealer(v.files);
  };
  const downloadQRCode = () => {
    const canvas = document.getElementById("myQRCode")?.querySelector("canvas");
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.download = "QRCode.png";
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  return (
    <Drawer
      title="Công ty"
      width={920}
      onClose={onClose}
      open={open}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
      extra={
        <Space>
          <Button onClick={() => onSubmit()}>Lưu</Button>
          <Button onClick={() => onSubmit(true)} type="primary">
            Lưu & đóng
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Row gutter={[24, 10]}>
        <Col span={12}>
          <p>Tên viết tắt</p>
          <Input
            value={data?.TenCTVT}
            placeholder="Nhập tên viết tắt"
            onChange={(e) => applyChange("TenCTVT", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Tên đầy đủ</p>
          <Input
            value={data?.TenCT}
            placeholder="Nhập tên đầy đủ"
            onChange={(e) => applyChange("TenCT", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Địa chỉ</p>
          <Input
            value={data?.DiaChi}
            placeholder="Nhập địa chỉ"
            onChange={(e) => applyChange("DiaChi", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Email</p>
          <Input
            value={data?.Email}
            placeholder="Nhập email"
            onChange={(e) => applyChange("Email", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>Website</p>
          <Input
            value={data?.Website}
            placeholder="Nhập website"
            onChange={(e) => applyChange("Website", e.target.value)}
          />
        </Col>
        <Col span={12}>
          <p>URL API</p>
          <Input
            value={data?.urlAPI}
            placeholder="Nhập url api"
            onChange={(e) => applyChange("urlAPI", e.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>IP</p>
          <Input
            value={data?.IP}
            placeholder="Nhập ip"
            onChange={(e) => applyChange("IP", e.target.value)}
          />
        </Col>
        <Col span={8}>
          <p>Số ngày xoá buyin</p>
          <InputNumber
            value={data?.SoNgayXoaBuyin}
            placeholder="Nhập số ngày"
            onChange={(v) => applyChange("SoNgayXoaBuyin", v)}
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={8}>
          <p>Màn hình giải</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaManHinh}
            placeholder="Chọn màn hình giải"
            onChange={(v) => applyChange("MaManHinh", v)}
            options={[
              { ID: 1, Name: "Màn hình 1" },
              { ID: 2, Name: "Màn hình 2" },
              { ID: 3, Name: "Màn hình 3" },
            ]}
            fieldNames={{ label: "Name", value: "ID" }}
          />
        </Col>
        <Col span={12}>
          <p>QRCode</p>
          <Input
            maxLength={60}
            value={data?.QRCode}
            onChange={(e) => applyChange("QRCode", e.target.value)}
          />
        </Col>
        <Col span={3}>
          <Checkbox
            onChange={(e) => applyChange("IsShowPrizepool", !e.target.value)}
            checked={data?.IsShowPrizepool}
            value={data?.IsShowPrizepool}
          >
            Prizepool
          </Checkbox>
        </Col>
        <Col span={3}>
          <Checkbox
            onChange={(e) => applyChange("IsShowAddon", !e.target.value)}
            checked={data?.IsShowAddon}
            value={data?.IsShowAddon}
          >
            Addon
          </Checkbox>
        </Col>{" "}
        <Col span={3}>
          <Checkbox
            onChange={(e) => applyChange("IsShowRebuy", !e.target.value)}
            checked={data?.IsShowRebuy}
            value={data?.IsShowRebuy}
          >
            Rebuy
          </Checkbox>
        </Col>
        {/* <Col span={6}>
          <p>Máy in</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaMayIn}
            placeholder="Chọn máy in"
            onChange={(v) => applyChange("MaMayIn", v)}
            options={mayIn}
            fieldNames={{ label: "TenMayIn", value: "ID" }}
          />
        </Col>
        <Col span={6}>
          <p>Mẫu in</p>
          <Select
            style={{
              width: "100%",
            }}
            value={data?.MaMauIn}
            placeholder="Chọn mẫu"
            onChange={(v) => applyChange("MaMauIn", v)}
            options={mauIn}
            fieldNames={{ label: "TenBM", value: "ID" }}
          />
        </Col> */}
        <Col span={24}>
          <div id="myQRCode">
            {data?.QRCode && (
              <>
                <QRCode value={data?.QRCode || ""} />
                <Button type="primary" onClick={downloadQRCode}>
                  Download
                </Button>
              </>
            )}
          </div>
        </Col>
        <Col span={12}>
          {data.Logo && (
            <img
              alt="example"
              style={{ width: "100%", height: 250 }}
              src={data.Logo}
            />
          )}
          <FileUpload
            mode="basic"
            // name="demo[]"
            // url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUpload(e)}
            chooseLabel="Tải logo"
          />
        </Col>
        <Col span={12}>
          {data.LogoGiaiDau && (
            <img
              alt="example gd"
              style={{ width: "100%", height: 250 }}
              src={data.LogoGiaiDau}
            />
          )}
          <FileUpload
            // style={{ maxWidth: "100px", width: 100 }}
            mode="basic"
            // name="demo[]"
            // url="/api/upload"
            accept="image/*"
            maxFileSize={1000000}
            onSelect={(e) => onBeforeUploadGD(e)}
            chooseLabel="Tải bg giải đấu"
          />
        </Col>
        <Col span={12}>
          {data.BackgroundDealerUrl && (
            <img
              alt="example gd"
              style={{ width: "100%", height: 250 }}
              src={data.BackgroundDealerUrl}
            />
          )}
          <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={100000000}
            onSelect={(e) => onBeforeUploadBGDealer(e)}
            chooseLabel="Tải background dealer"
          />
        </Col>
        <Col span={12}>
          {data.TableDealderUrl && (
            <img
              alt="example gd"
              style={{ width: "100%", height: 250 }}
              src={data.TableDealderUrl}
            />
          )}
          <FileUpload
            mode="basic"
            accept="image/*"
            maxFileSize={100000000}
            onSelect={(e) => onBeforeUploadTableDealer(e)}
            chooseLabel="Tải table dealer"
          />
        </Col>
      </Row>
    </Drawer>
  );
};
export default forwardRef(FormAdd);
