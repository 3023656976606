// Chakra imports
import {
  Portal,
  Box,
  useDisclosure,
  Text,
  Button,
  Link,
} from "@chakra-ui/react";
// Layout components
import Navbar from "components/navbar/NavbarAdmin.js";
import Sidebar from "components/sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import { SignalrContextProvider } from "contexts/SignalrContext";
import React, { useEffect, useState, useContext } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ThongBaoContextProvider } from "contexts/ThongBaoContext";

import routes from "routes.js";
// Custom Chakra theme
export default function Dashboard(props) {
  const history = useHistory();
  const { ...rest } = props;
  // states and functions
  const [fixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes?.length; i++) {
      if (routes[i].children) {
        let collapseActiveRoute = getActiveRoute(routes[i].children);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      }
      // else if (routes[i].category) {
      // 	let categoryActiveRoute = getActiveRoute(routes[i].items);
      // 	if (categoryActiveRoute !== activeRoute) {
      // 		return categoryActiveRoute;
      // 	}
      // }
      else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].label;
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getActiveNavbarText = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbarText(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbarText(routes[i].items);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].messageNavbar;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    // let _menu = JSON.parse(localStorage.getItem('menu'));
    // console.log(routes);
    return routes.map((prop, key) => {
      // console.log(prop);
      if (prop.layout === "/admin") {
        if (prop.children?.length > 0) {
          return getRoutes(prop.children);
        }
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      // if (prop.collapse) {
      // 	return getRoutes(prop.items);
      // }
      // if (prop.category) {
      // 	return getRoutes(prop.items);
      // } else {
      // 	return null;
      // }
    });
  };
  // document.documentElement.dir = 'ltr';
  const { onOpen } = useDisclosure();
  // document.documentElement.dir = 'ltr';
  if (!localStorage.getItem("token")) {
    history.push("/auth/sign-in");
  }
  return (
    <Box>
      <ThongBaoContextProvider>
        <SignalrContextProvider>
          <SidebarContext.Provider
            value={{
              toggleSidebar,
              setToggleSidebar,
            }}
          >
            <Sidebar
              routes={routes.filter((v) => v?.layout === "/admin")}
              display="none"
              {...rest}
            />
            <Box
              // float="right"
              // minHeight="100vh"
              height={{ base: "100%", xl: "calc( 100% - 290px )" }}
              // overflow="auto"
              // position="relative"
              // maxHeight="100%"
              // w={{ base: "100%", xl: "calc( 100% - 290px )" }}
              // maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
              transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
              transitionDuration=".2s, .2s, .35s"
              transitionProperty="top, bottom, width"
              transitionTimingFunction="linear, linear, ease"
            >
              {/* <Portal>
            <Box>
              <Navbar
                onOpen={onOpen}
                logoText={"POKER"}
                brandText={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                message={getActiveNavbarText(routes)}
                fixed={fixed}
                {...rest}
              />
            </Box>
          </Portal> */}

              {getRoute() ? (
                <Box
                  mx="auto"
                  // p={{ base: "20px", md: "30px" }}
                  p="10px"
                  // pe="20px"
                  // minH="100vh"
                >
                  <Switch>
                    {getRoutes(routes)}
                    <Redirect from="/" to="/admin/default" />
                  </Switch>
                </Box>
              ) : null}
            </Box>
          </SidebarContext.Provider>
        </SignalrContextProvider>
      </ThongBaoContextProvider>
    </Box>
  );
}
