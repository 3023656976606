import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/bg-login3.jpeg";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { HomeService } from "services/HomeService";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { OtherService } from "services/OtherService";
import { PermissionService } from "services/PermissionService";
import { NhanVienService } from "services/NhanVienService";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const history = useHistory();
  const [show, setShow] = React.useState(false);
  const defaultData = {
    TenCTDKVT: localStorage.getItem("MaCTDK"),
    Email: "",
    Password: "",
  };
  const [data, setData] = useState(defaultData);
  const handleClick = () => setShow(!show);
  const [err, setErr] = useState("");
  const [loading, setLoading] = React.useState(false);
  // const isTV = () => {
  //   const userAgent = navigator.userAgent.toLowerCase();

  //   return (
  //     userAgent.includes("smart-tv") ||
  //     userAgent.includes("tv") ||
  //     userAgent.includes("bravia") ||
  //     userAgent.includes("tizen") ||
  //     userAgent.includes("webos") ||
  //     userAgent.includes("tcl") || // Thêm kiểm tra cho TCL TV
  //     userAgent.includes("roku")
  //   );
  // };

  const login = async () => {
    try {
      setLoading(true);
      let _res = await HomeService.Auth.login(data);
      if (_res.status === 200) {
        localStorage.setItem("token", _res?.acessToken);
        localStorage.setItem("MaCTDK", data.TenCTDKVT);
        let _resnv = await NhanVienService.getByID();
        localStorage.setItem("NhanVien", JSON.stringify(_resnv?.data));
        let _resmenuct = await PermissionService.TheoCongTy.getMenu();
        localStorage.setItem("menu_congty", JSON.stringify(_resmenuct.data));
        let _resmenu = await HomeService.Auth.getMenu();
        localStorage.setItem("menu", JSON.stringify(_resmenu.data));
        if (localStorage.getItem("deviceID")) {
          await OtherService.addDevice({
            DeviceID: localStorage.getItem("deviceID"),
            DeviceName: "",
            UserAgent: navigator.userAgent.toLowerCase(),
          });
        }
        if (_resmenu?.data?.some((v) => v.FormID === 193)) {
          history.push("/admin/danh-sach-giai");
        } else {
          history.push("/admin/default");
        }
      } else {
        setErr(_res.message);
        localStorage.removeItem("url");
      }
      setLoading(false);
    } catch {
      setLoading(false);
      localStorage.removeItem("url");
    }
  };
  const applyChange = (p, v) => {
    let _data = Object.assign({}, data);
    _data[p] = v;
    setData(_data);
  };
  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Đăng nhập
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
          >
            Nhập mã công ty, email và mật khẩu để đăng nhập!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <FormControl>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Mã công ty<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="BEESKY"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data.TenCTDKVT}
              onChange={(e) => applyChange("TenCTDKVT", e.target.value)}
            />
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@simmmple.com"
              mb="24px"
              fontWeight="500"
              size="lg"
              value={data.Email}
              onChange={(e) => applyChange("Email", e.target.value)}
            />
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              display="flex"
            >
              Mật khẩu<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Input
                isRequired={true}
                fontSize="sm"
                placeholder="Min. 8 characters"
                mb="24px"
                size="lg"
                type={show ? "text" : "password"}
                variant="auth"
                value={data.Password}
                onChange={(e) => applyChange("Password", e.target.value)}
              />
              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {/* <Flex justifyContent='space-between' align='center' mb='24px'>
              <FormControl display='flex' alignItems='center'>
                <Checkbox
                  id='remember-login'
                  colorScheme='brandScheme'
                  me='10px'
                />
                <FormLabel
                  htmlFor='remember-login'
                  mb='0'
                  fontWeight='normal'
                  color={textColor}
                  fontSize='sm'>
                  Keep me logged in
                </FormLabel>
              </FormControl>
              <NavLink to='/auth/forgot-password'>
                <Text
                  color={textColorBrand}
                  fontSize='sm'
                  w='124px'
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Flex> */}
            {err && <p style={{ color: "red" }}>{err}</p>}

            <Button
              onClick={() => login()}
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              isLoading={loading}
            >
              Đăng nhập
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"
          >
            <Text color={textColorDetails} fontWeight="400" fontSize="14px">
              Chưa có tài khoản?
              <NavLink to="/auth/sign-up">
                <Text
                  color={textColorBrand}
                  as="span"
                  ms="5px"
                  fontWeight="500"
                >
                  Tạo tài khoản mới
                </Text>
              </NavLink>
            </Text>
          </Flex>
          {/* <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='start'
            maxW='100%'
            mt='0px'>
            <Text color={textColorDetails} fontWeight='400' fontSize='14px'>
              Not registered yet?
              <NavLink to='/auth/sign-up'>
                <Text
                  color={textColorBrand}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an Account
                </Text>
              </NavLink>
            </Text>
          </Flex> */}
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
