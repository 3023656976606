import React, { useState, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  Space,
  Drawer,
  notification,
  Table,
  Upload,
  Badge,
  Tooltip,
  Select,
} from "antd";
import _ from "lodash";
import { UploadOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { CardService } from "services/CardService";
import { ConvertExcelDateToJSDate } from "layouts/ultis/common";
const FormImport = (props, ref) => {
  const { onAfterSubmit, options, filterCondition } = props;
  const [api, contextHolder] = notification.useNotification();

  const [data, setData] = useState([]);
  const [dataError, setDataError] = useState([]);
  const [isupdate, setIsUpdate] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const defaultErrors = { TenKH: null };
  const [errors, setErrors] = useState(defaultErrors);
  const [sheetExcel, setSheetExcel] = useState();
  const [workbook, setWorkbook] = useState(null);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  useImperativeHandle(ref, () => ({
    show: async () => {
      setOpen(true);
      setIsUpdate(false);
    },
  }));
  const onClose = () => {
    setOpen(false);
    setData([]);
    setLoading(false);
  };
  const columns = [
    {
      title: "Tên khách hàng",
      width: 150,
      render: (item) =>
        item?.error ? (
          <Tooltip title={item?.error}>
            <Badge count={1}>
              {/* <Avatar shape="square" size="large" /> */}
              <p>{item?.TenCongTy}</p>
            </Badge>
          </Tooltip>
        ) : (
          <p>{item?.TenCongTy}</p>
        ),
    },
    {
      title: "Số CMND/ Hộ chiếu",
      dataIndex: "SoCMND",
      key: "SoCMND",
      width: 100,
    },
    {
      title: "Ngày cấp",
      dataIndex: "NgayCap",
      key: "NgayCap",
      width: 100,
    },
    {
      title: "Nơi cấp",
      dataIndex: "NoiCap",
      key: "NoiCap",
      width: 100,
    },
    {
      title: "Số điện thoại",
      dataIndex: "DiDong",
      key: "DiDong",
      width: 150,
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      width: 150,
    },
    {
      title: "Địa chỉ",
      dataIndex: "DiaChi",
      key: "DiaChi",
      width: 150,
    },
    {
      title: "Quốc gia",
      dataIndex: "QuocGia",
      key: "QuocGia",
      width: 150,
    },
    {
      title: "Mã số thẻ",
      dataIndex: "SoThe",
      key: "SoThe",
      width: 150,
    },
    {
      title: "Mã vạch",
      dataIndex: "MaVach",
      key: "MaVach",
      width: 150,
    },
    {
      title: "Loại thẻ",
      dataIndex: "LoaiThe",
      key: "LoaiThe",
      width: 150,
    },
    {
      title: "Ngày hiệu lực",
      dataIndex: "NgayHL",
      key: "NgayHL",
      width: 150,
    },
    {
      title: "Ngày hết hạn",
      dataIndex: "NgayHH",
      key: "NgayHH",
      width: 150,
    },
  ];
  // const [col, setCol] = useState(columns);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const sampleData = [
    {
      TenCongTy: "Nguyễn Hoà",
      SoCMND: "0",
      NgayCap: "",
      NoiCap: "",
      DiDong: "0",
      Email: "",
      DiaChi: "VietNam",
      QuocGia: "VietNam",
      SoThe: "0007137457",
      MaVach: "0007137457",
      LoaiThe: "Member card",
      NgayHL: "2024-09-06",
      NgayHH: "2027-08-22",
    },
    {
      TenCongTy: "Nguyễn Hoà",
      SoCMND: " ",
      NgayCap: "",
      NoiCap: "",
      DiDong: null,
      Email: "",
      DiaChi: "",
      QuocGia: "",
      SoThe: "0123",
      MaVach: "55551",
      LoaiThe: "Member",
      NgayHL: "",
      NgayHH: "",
    },
  ];
  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(sampleData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataEX = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataEX, "Template card" + fileExtension);
  };

  const loadDataImport = (data) => {
    let res = [];
    data?.map((item, i) => {
      if (i > 0) {
        console.log(item[0], typeof item[1].toString());
        console.log(typeof item[4]);

        res.push({
          TenCongTy: item[0]?.toString(),
          SoCMND: item[1]?.toString(),
          NgayCap:
            typeof item[2] === "number"
              ? ConvertExcelDateToJSDate(item[2])
              : item[2],
          NoiCap: item[3]?.toString(),
          DiDong: item[4]?.toString(),
          Email: item[5]?.toString(),
          DiaChi: item[6]?.toString(),
          QuocGia: item[7]?.toString(),
          SoThe: item[8]?.toString(),
          MaVach: item[9]?.toString(),
          LoaiThe: item[10]?.toString(),
          NgayHL:
            typeof item[11] === "number"
              ? ConvertExcelDateToJSDate(item[11])
              : item[11],
          NgayHH:
            typeof item[12] === "number"
              ? ConvertExcelDateToJSDate(item[12])
              : item[12],
        });
      }
    });
    setData(res);
  };
  const onSubmit = async (close) => {
    setLoading(true);
    let _res = await CardService.import({
      List: data,
    });
    setLoading(false);
    if (_res.status === 2000) {
      openNotification("success", "topRight", "Import thành công");
      let _data = _.cloneDeep(data);
      _data = _data.map((item) => {
        if (_.map(_res.data, "maVach").includes(item.MaVach))
          return {
            ...item,
            error: _res.data?.find((v) => v.maVach === item.MaVach)?.error,
          };
      });
      setData(_data);
    }
  };
  const handleSheet = (value) => {
    const wsname = workbook.SheetNames[value];
    const ws = workbook.Sheets[wsname];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    loadDataImport(data);
    // setCol(columns);
  };
  return (
    <Drawer
      title="Import Excel"
      width="100%"
      onClose={onClose}
      open={open}
      extra={
        <Space>
          <Button onClick={() => exportToCSV()} type="dashed">
            Tải file mẫu
          </Button>
          {sheetExcel && (
            <Select
              placeholder="Chọn Sheet"
              style={{
                width: 115,
                border: "1px solid #d9d9d9",
                borderBottom: "3px solid #d9d9d",
                borderRadius: "5px",
                padding: "0 2px 0 10px",
              }}
              onChange={handleSheet}
              options={sheetExcel}
            />
          )}

          <Upload
            accept=".txt, .csv, .xlsx, .xls"
            showUploadList={false}
            beforeUpload={(file) => {
              setWorkbook(null);
              setSheetExcel(null);
              const reader = new FileReader();
              const rABS = !!reader.readAsBinaryString;
              reader.onload = (e) => {
                const bstr = e.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
                const sheets = wb.SheetNames.map((item, index) => ({
                  value: index,
                  label: item,
                }));
                if (sheets.length === 1) {
                  const wsname = wb.SheetNames[0];
                  const ws = wb.Sheets[wsname];
                  const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                  loadDataImport(data);
                  // setCol(columns);
                } else {
                  setWorkbook(wb);
                  setData();
                  setSheetExcel(sheets);
                }
              };
              if (rABS) reader.readAsBinaryString(file);
              else reader.readAsArrayBuffer(file);
            }}
          >
            <Button icon={<UploadOutlined />}>Upload File</Button>
          </Upload>
          <Button
            loading={loading}
            onClick={() => onSubmit(true)}
            type="primary"
          >
            Lưu
          </Button>
        </Space>
      }
    >
      {contextHolder}
      <Table
        dataSource={data}
        columns={columns}
        scroll={{
          y: window.innerHeight - 250,
          x: 2000,
        }}
      />
    </Drawer>
  );
};
export default forwardRef(FormImport);
