import axiosApi from "./api_helper";
export const NhanVienService = {
  getNhanVien: async () => {
    return await axiosApi()
      .post("api/poker/get-nhan-vien")
      .then((res) => res.data);
  },
  getByID: async () => {
    return await axiosApi()
      .post("api/nhanvien/get-byid")
      .then((res) => res.data);
  },
  checkAccount: async () => {
    return await axiosApi()
      .post("api/nhanvien/check-account")
      .then((res) => res.data);
  },
  addNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/nhanvien/add", payload)
      .then((res) => res.data);
  },
  updateNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/poker/update-nhan-vien", payload)
      .then((res) => res.data);
  },
  deleteNhanVien: async (payload) => {
    return await axiosApi()
      .post("api/poker/delete-nhan-vien", payload)
      .then((res) => res.data);
  },
  getQuyDanh: async () => {
    return await axiosApi()
      .post("api/poker/get-quy-danh")
      .then((res) => res.data);
  },
  getNhomQuyen: async () => {
    return await axiosApi()
      .post("api/poker/get-nhom-quyen")
      .then((res) => res.data);
  },
  changePass: async (payload) => {
    return await axiosApi()
      .post("api/ChangePassword", payload)
      .then((res) => res.data);
  },
  resetPass: async (payload) => {
    return await axiosApi()
      .post("api/reset-password", payload)
      .then((res) => res.data);
  },
  getAction: async (payload) => {
    return await axiosApi()
      .post("api/get-action", payload)
      .then((res) => res.data);
  },
};
