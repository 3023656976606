import React, { useState, useEffect, createContext } from "react";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

const ThongBaoContext = createContext({ name: "signalr" });
export const ThongBaoContextProvider = ({ children }) => {
  const [hubConnection, setHubConnection] = useState(null);
  useEffect(() => {
    try {
      let nhanvien = JSON.parse(localStorage.getItem("NhanVien"));
      let ctdk = localStorage.getItem("MaCTDK");
      (async () => {
        let connection = new HubConnectionBuilder()
          .withUrl("https://api-poker.beesky.vn/thongbao")
          .withAutomaticReconnect()
          .configureLogging(LogLevel.Information)
          .build();
        connection.onreconnected(async (connectionId) => {
          connection.invoke(ctdk.toLowerCase() + String(nhanvien.MaNV));
        });
        await connection.start();
        await connection.invoke(
          "JoinGroup",
          ctdk.toLowerCase() + String(nhanvien.MaNV)
        );
        console.log("connection",connection);
        
        setHubConnection(connection);
      })();
    } catch {}
    return () => {
      if (hubConnection) {
        hubConnection.stop();
      }
    };
  }, []);
  return (
    <ThongBaoContext.Provider value={{ hubConnection }}>
      {children}
    </ThongBaoContext.Provider>
  );
};
export default ThongBaoContext;
