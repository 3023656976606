import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Popconfirm,
  Table,
  Tabs,
  Tooltip,
  notification,
  Flex,
} from "antd";
import FormAdd from "./FormAdd";
import { CiTrash } from "react-icons/ci";
import { MdEdit } from "react-icons/md";
import _ from "lodash";
import Toolbar from "components/Toolbar";
import TabPane from "antd/es/tabs/TabPane";
import { CardService } from "services/CardService";
import { Format_Date } from "layouts/ultis/common";
import { Format_Currency } from "layouts/ultis/common";
import { SanPhamService } from "services/SanPhamService";
import { CategoryService } from "services/CategoryService";

export default function Settings() {
  const [api, contextHolder] = notification.useNotification();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const refDetail = useRef(null);
  const [activeTab, setActiveTab] = useState("0");
  const [loaithe, setLoaithe] = useState([]);
  const [loaisp, setLoaisp] = useState([]);
  const openNotification = (type, placement, message) => {
    api[type]({
      message: `Thông báo`,
      description: message,
      placement,
    });
  };
  const tabs = [
    {
      title: "Loại thẻ",
      key: "0",
    },
    {
      title: "Nhóm khác hàng",
      key: "1",
    },
    {
      title: "Cài đặt máy in",
      key: "2",
    },
    {
      title: "Quốc gia",
      key: "3",
    },
  ];

  useEffect(() => {
    filter(activeTab);
  }, []);
  const filter = async (tab) => {
    setLoading(true);
    if (tab === "0") {
      let _res = await CardService.Type.get();
      if (_res.data) setData(_res.data);
    } else if (tab === "1") {
      let _res = await CardService.Customer.getGroup();
      if (_res.data) setData(_res.data);
    } else if (tab === "2") {
      let _res = await CategoryService.Khac.getCatDatMayIn();
      if (_res.data) setData(_res.data);
    } else if (tab === "3") {
      let _res = await CategoryService.Khac.getQuocGia();
      if (_res.data) setData(_res.data);
    }
    setLoading(false);
  };
  const onAfterSubmit = () => {
    filter(activeTab);
  };
  const columns = [
    {
      title: "Tên loại thẻ",
      dataIndex: "TenLT",
    },
    {
      title: "Mức điểm đạt",
      dataIndex: "MucDat",
    },

    {
      title: "Điểm duy trì",
      dataIndex: "DuyTri",
    },

    {
      title: "Số tiền / điềm",
      dataIndex: "TyGiaDiem",
    },
    {
      title: "Số tiền giới thiệu / điểm",
      dataIndex: "TyGiaDiemGioiThieu",
    },
    {
      title: "1 điểm / tiền",
      dataIndex: "MotDiemTrenSoTien",
    },
    {
      title: "Tỷ lệ buy",
      dataIndex: "TyLeBuy",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center" gap={10}>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá loại thẻ"
              description="Bạn có chắc chắn muốn xoá loại thẻ?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button danger ghost icon={<CiTrash />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Tên nhóm",
      dataIndex: "Name",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá thẻ"
              description="Bạn có chắc chắn muốn xoá thẻ?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: "Tên server",
      dataIndex: "TenServer",
    },
    {
      title: "Tên máy in",
      dataIndex: "TenMayIn",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <div>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                style={{ marginLeft: 10 }}
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá danh mục"
              description="Bạn có chắc chắn muốn xoá danh mục?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button
                  style={{ marginLeft: 10 }}
                  danger
                  ghost
                  icon={<CiTrash />}
                />
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];
  const columns4 = [
    {
      title: "Tên quốc gia",
      dataIndex: "TenQG",
    },
    {
      title: "Tên viết tắt",
      dataIndex: "TenVT",
    },
    {
      title: "Mã quốc gia",
      dataIndex: "MaQG",
    },
    {
      title: () => {
        return (
          <Flex justify="center">
            <Button
              type="primary"
              onClick={() => refDetail.current.show(activeTab)}
            >
              Thêm
            </Button>
          </Flex>
        );
      },
      fixed: "right",
      width: "120px",
      render: (item) => {
        return (
          <Flex justify="center" gap={10}>
            <Tooltip placement="topRight" title="Sửa">
              <Button
                type="primary"
                ghost
                icon={<MdEdit />}
                onClick={() => refDetail.current.update(item, activeTab)}
              />
            </Tooltip>

            <Popconfirm
              title="Xoá loại thẻ"
              description="Bạn có chắc chắn muốn xoá loại thẻ?"
              okText="Đồng ý"
              cancelText="Không"
              onConfirm={() => remove(item.ID)}
            >
              <Tooltip placement="topRight" title="Xoá">
                <Button danger ghost icon={<CiTrash />} />
              </Tooltip>
            </Popconfirm>
          </Flex>
        );
      },
    },
  ];
  const remove = async (value) => {
    let _res = null;
    if (activeTab === "0") {
      _res = await CardService.Type.delete({ ID: Number(value) });
    } else if (activeTab === "1") {
      _res = await CardService.Customer.deleteGroup({ ID: Number(value) });
    } else if (activeTab === "2") {
      _res = await CategoryService.Khac.deleteCatDatMayIn({
        ID: Number(value),
      });
    } else if (activeTab === "3") {
      _res = await CategoryService.Khac.deleteQuocGia({
        ID: Number(value),
      });
    }
    if (_res.status === 2000) {
      let _data = _.cloneDeep(data);
      let fil = _data.filter((v) => v.ID !== Number(value));
      setData(fil);
      openNotification("success", "topRight", "Xoá  thành công");
    } else {
      openNotification("error", "topRight", _res?.message);
    }
  };
  const onChange = (newActiveKey) => {
    setActiveTab(newActiveKey);
    filter(newActiveKey);
  };
  return (
    <Box>
      <Tabs onChange={onChange} activeKey={activeTab}>
        {tabs?.map((item) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              <Table
                loading={loading}
                dataSource={data}
                columns={
                  activeTab === "0"
                    ? columns
                    : activeTab === "1"
                    ? columns2
                    : activeTab === "2"
                    ? columns3
                    : columns4
                }
                scroll={{
                  y: window.innerHeight - 350,
                  // x: 2500
                }}
                size="small"
              />
            </TabPane>
          );
        })}
      </Tabs>
      <FormAdd ref={refDetail} onAfterSubmit={onAfterSubmit} />
      {contextHolder}
    </Box>
  );
}
