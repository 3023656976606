import React, { useEffect, useState } from "react";
import { Table, Row, Col, Segmented } from "antd";
import _ from "lodash";
import "dayjs/locale/vi";
import FilterModal from "./FilterModal";
import Toolbar from "components/Toolbar";
import { OtherService } from "services/OtherService";
import { Format_Datetime } from "layouts/ultis/common";

export default function Settings() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);

  const [filterCondition, setFilterCondition] = useState({
    TuNgay: new Date(),
    DenNgay: new Date(),
    NhanVien: ",,",
    ThaoTac: ",,",
  });
  useEffect(() => {
    // loadBuyin(filterCondition);
  }, []);

  const loadBuyin = async (_filter) => {
    setLoading(true);
    let _res = await OtherService.getHistoryThaoTac(_filter);
    setData(_res.data);
    setLoading(false);
  };
  // const loadCategories = async () => {
  //   let _resCT = await BuyinService.getCompany();
  //   setCompanies(_resCT.data);
  // };
  const handleNexTab = async (value) => {
    console.log(value, "test next page");

    let _ft = _.cloneDeep(filterCondition);
    if (value === "LichSuThaoTac") {
      setLoading(true);
      let _res = await OtherService.getHistoryThaoTac(_ft);
      setData(_res.data);
      setLoading(false);
    }
    if (value === "LichSuThongBao") {
      setLoading(true);
      let _res = await OtherService.getHistoryThaoTac(_ft);
      setData(_res.data);
      setLoading(false);
    }
    if (value === "LichSuKhac") {
      setLoading(true);
      let _res = await OtherService.getHistoryThaoTac(_ft);
      setData(_res.data);
      setLoading(false);
    }
  };
  const actions = {
    ADD: "Thêm mới",
    UPDATE: "Cập nhật",
    DELETE: "Xóa",
  };
  const columns = [
    {
      title: "Ngày thao tác",
      dataIndex: "NgayTao",
      width: 150,
      render: (item) => item && Format_Datetime(item),
    },
    {
      title: "Thao tác",
      dataIndex: "Action",
      width: 100,
      render: (item) => actions[item],
    },
    {
      title: "Nhân viên",
      dataIndex: "HoTen",
      width: 200,
    },
    {
      title: "Nội dung",
      dataIndex: "NoiDung",
    },
  ];
  const onChangeFilter = (_filter) => {
    loadBuyin(_filter);
    setFilterCondition(_filter);
  };
  const onSearch = (v) => {
    let _ft = _.cloneDeep(filterCondition);
    _ft.InputSearch = v;
    setFilterCondition(_ft);
  };
  useEffect(() => {
    let time = null;
    if (!time) {
      time = setTimeout(() => {
        loadBuyin(filterCondition);
      }, 800);
    }
    return () => clearTimeout(time);
  }, [filterCondition.InputSearch]);
  return (
    <Row gutter={15}>
      <Col span={4}>
        <FilterModal
          companies={companies}
          filterCondition={filterCondition}
          onChangeFilter={onChangeFilter}
        />
      </Col>
      <Col span={20}>
        <Toolbar
          left={() => (
            <Segmented
              options={[
                {
                  label: "Lịch sử thao tác",
                  value: "LichSuThaoTac",
                },
                {
                  label: "Lịch sử thông báo",
                  value: "LichSuThongBao",
                },
                {
                  label: "Lịch sử khác",
                  value: "LichSuKhac",
                },
              ]}
              defaultValue="LichSuThaoTac"
              onChange={(value) => handleNexTab(value)}
            />
            // <Input
            //   placeholder="Tìm kiếm"
            //   style={{
            //     width: "300px",
            //     padding: "4px 11px",
            //     borderRadius: 10,
            //   }}
            //   onChange={(e) => onSearch(e.target.value)}
            // />
          )}
        />
        <Table
          style={{ height: 100 }}
          loading={loading}
          dataSource={data}
          columns={columns}
          scroll={{
            y: window.innerHeight - 250,
          }}
          size="small"
          virtual={true}
          pagination={{
            showSizeChanger: true,
            defaultPageSize: 20,
            pageSizeOptions: ["20", "50", "500", "5000"],
            position: ["bottomRight"],
          }}
        />
      </Col>
    </Row>
  );
}
