import axiosApi from "./api_helper";

export const HeThongService = {
  ThongBao: {
    get: async (payload) => {
      return await axiosApi()
        .post("api/hethong/noti/get", payload)
        .then((res) => res.data);
    },
    getHistoryNotification: async (payload) => {
      return await axiosApi()
        .post("api/hethong/noti/get-filter", payload)
        .then((res) => res.data);
    },
  },
};
